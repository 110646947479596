import PropTypes from 'prop-types';
import Head from 'next/head';
import Layout from '../layout';
import { Wrap } from '../../styled';
import { Container, Text } from './styled';

const statusCodes = {
  400: 'Bad Request',
  404: 'This page could not be found',
  500: 'Internal Server Error',
  501: 'Not Implemented',
};

const Error = ({ statusCode }) => {
  const title = statusCodes[statusCode];

  return (
    <Layout>
      <Head>
        <title>{`${statusCode}: ${title}`}</title>
      </Head>
      <Container>
        <Wrap>
          <Text>{statusCode}</Text>
          <Text>{title}</Text>
        </Wrap>
      </Container>
    </Layout>
  );
};

Error.propTypes = {
  statusCode: PropTypes.number.isRequired,
};

export default Error;
