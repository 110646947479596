import styled from 'styled-components';
import media from '../../styled/media';

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px 0;
  background-size: cover;
  background-position: center;
  background-image: url('/images/404-error-page-bg.jpg');
  ${media.tablet`
    padding: 120px 0;
  `}
`;

export const Text = styled.p`
  color: #fff;
  font-size: 38px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  ${media.tablet`
    font-size: 44px;
  `}
`;
